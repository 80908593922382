import { FC, ReactNode, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../side-bar/Sidebar';
import TopNavBar from '../top-navbar/TopNavBar';
import {
  selectCommon,
  setAfterLoginFlag
} from 'src/modules/common/services/common.service';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from 'src/providers/AuthguardContext';
import { goToRoute } from 'src/auth/login/Utils';
import BackGroundOverLay from 'src/modules/Dashboard/component/BackGroundOverLay';
import {
  changeTourStatus,
  getTourComponent,
  getTourStatus
} from './tourGuideApi';
import { showErrorMessage } from 'src/shared/components/toaster/Toast';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const { userData, afterLogin } = useSelector(selectCommon);
  const dispatch = useDispatch<any>();
  const [componentTour, setComponentTour] = useState({
    tourDetails: [],
    tourStatus: true,
    id: ''
  });
  const [tourComponents, setTourComponents] = useState([]);
  const TourRoles = ['business-secondary-admin', 'business-primary-admin'];

  const { login } = useAuth();
  const navigate = useNavigate();

  // Impersonet User
  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData?.accessToken) login(userData);
      if (!afterLogin) {
        const route = goToRoute(userData);
        navigate('../' + route, { replace: true });
        dispatch(setAfterLoginFlag());
      }
      if (TourRoles.includes(userData.role)) {
        checkUserTourStatus();
      }
    }
  }, [userData]);

  const checkUserTourStatus = async () => {
    const res = await getTourComponent();
    setTourComponents(res);
  };

  useEffect(() => {
    if (tourComponents.length > 0) {
      checkForTourOfMyDocs(tourComponents);
    }
  }, [tourComponents]);

  const checkForTourOfMyDocs = async (tourComponents) => {
    let checkForMyDoComponent = tourComponents?.filter(
      (item) => item?.component === 'My Docs'
    );
    if (checkForMyDoComponent[0]?.id) {
      const res = await getTourStatus(checkForMyDoComponent[0]?.id);
      setComponentTour({ id: checkForMyDoComponent[0]?.id, ...res });
    }
  };

  const handleClose = async (changeTourId, stepCount) => {
    try {
      await changeTourStatus(changeTourId, stepCount);
    } catch (error) {
      showErrorMessage(error.message, {});
    } finally {
      setComponentTour((prev) => ({ ...prev, tourStatus: true }));
    }
  };
  return (
    <Box
      className={
        theme.palette.mode === 'dark'
          ? 'layoutbox MuiPageTitlewrapperDark p-relative screenSize'
          : 'layoutbox MuiPageTitlewrapperLight p-relative screenSize '
      }
    >
      {!componentTour.tourStatus && (
        <Box className="backgroundOverlay">
          <BackGroundOverLay
            handleClose={handleClose}
            tourDetails={componentTour.tourDetails}
            tourId={componentTour.id}
          />
        </Box>
      )}
      <TopNavBar></TopNavBar>
      <Sidebar componentTour={componentTour} />
      <Box
        className="main"
        sx={{
          pt: `${theme.header.height}`,
          [theme.breakpoints.up('md')]: {
            ml: `${theme.sidebar.width}`
          }
        }}
      >
        <Box sx={{ mb: 7, mx: 3, px: 5 }} className="mainScroll">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarLayout;
